function TokenUndefined(routevar) {
  const token = localStorage.getItem("jff_token");
  if (token === "undefined") {
    localStorage.removeItem("jff_token");
    localStorage.removeItem("category");
    localStorage.removeItem("permission");
    window.location.href = `${process.env.REACT_APP_LOGIN_REDIRECTION_URL}/${routevar}`;
  }
 
 
}

export default TokenUndefined;
