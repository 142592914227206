import React from 'react'
import footerlogo from '../../HtmlMainPage/images/fixed-logo-white-text.png'

const Footer = () => {
  return (
    <div className="footer">
      <div className='f-logo'>
        <img src={footerlogo}
          style={{
            height: "60px",
            objectFit: "contain",
            marginLeft: "30px"
          }} />
      </div>
      <p>Copyright © PMI. All Rights Reserved.</p>
    </div>

  );
}

export default Footer