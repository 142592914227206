import React, { useEffect, useState } from "react";
// import logo from "../images/logo-01.png";
import logo from "../images/pmi-grand-h-logo.png";
import mobilelogo from "../images/pmi-grand-w-logo.png";
import fixedlogo from "../images/fixed-logo.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import axios from "axios";
import { message } from "antd";
import Select from "react-select";

const MissingDetailsOne = ({
  setDataShow,
  dataShow,
  currentBuyer,
  setCurrentBuyer,
  reload,
  setReload,
}) => {
  const [designation, setDesignation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [dealIn, setDealIn] = useState("");
  const [photo, setPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [pin_code, setPin_code] = useState("");

  const [city, setCity] = useState("");

  const [state, setState] = useState("");

  const [postal_address, setPostal_address] = useState("");
  const designationArray = ["Select", "CEO", "Director", "Owner", "Staff"];
  const handleCitychange = (e) => {
    setCity(e.target.value);
  };

  const handleStatechange = (e) => {
    setState(e.target.value);
  };
  // const handleProfessionChange = (e) => {
  //   setProfession(e.target.value);
  // };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  // function validateGSTLength(gstNumber) {
  //   const gstRegex = /^[0-9A-Z]{12}$/;
  //   return gstRegex.test(gstNumber);
  // }

  // const handleGstNumberChange = (e) => {
  //   const gstRegex = /^[0-9A-Z]{12}$/;

  //   const value = e.target.value;

  //   console.log("validateGSTLength", validateGSTLength(e.target.value));
  //   // if (!validateGSTLength(value)) {
  //   //   setErrorMessage("Enter valid Gst number");
  //   // }

  //   setGstNumber(e.target.value);
  // };

  // useEffect(() => {
  //   if (gstNumber === "" || gstNumber?.length === 12) {
  //     setErrorMessage(null);
  //   }
  // }, [gstNumber]);

  // const handleBlur = () => {
  //   if (!validateGSTLength(gstNumber)) {
  //     setErrorMessage("Enter valid Gst number");
  //   }
  // };

  const handleDealInChange = (e) => {
    setDealIn(e.target.value);
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    console.log(
      "adasdassdsddad",
      city,
      state,
      companyName,
      postal_address,
      pin_code
    );
    if (!city || !state || !companyName || !postal_address || !pin_code) {
      setErrorMessage("Please fill in all fields");
      return;
    }
    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("city", city);
    formData.append("state", state);

    formData.append("address", postal_address);
    // formData.append("gst_number", gstNumber);
    formData.append("pin_code", pin_code);
    // formData.append("gst_photo", photo);
    // formData.append("barcode", barCode)
    console.log("asdassasasdsdadadasadasdasddasaasdsdasd", currentBuyer);
    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/edit-profession-buyer`,
        formData,
        {
          headers: {
            barcode: localStorage.getItem("barcode"),
          },
        }
      )
      .then((resData) => {
        message.success(resData.data.message);
        if (resData.data.message === "Otp Send") {
          setDataShow(dataShow + 1);
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log("error:", error.message);
      });

    setDataShow(dataShow + 1);
  };

  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);

  const handlePincodechange = (e) => {
    const value = e.target.value;

    setPin_code(value);
    // Check if the value contains only numbers
    if (!/^\d*$/.test(value)) {
      setErrorMessage("pincode contain numbers only");
      // If the value contains non-numeric characters, don't update the state
      return;
    } else {
      setErrorMessage(null);
    }

    // Update the state only if the value is numeric
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/get-detail-buyer`, "", {
        headers: {
          barcode: localStorage.getItem("barcode"),
        },
      })
      .then((resData) => {
        // localStorage.setItem("barcode", resData.data.data.barcode);
        console.log("hello", resData.data.data);
        setCompanyName(resData.data.data.company_name);
        setCity(resData.data.data.city);
        setState(resData.data.data.state);

        // setPhoto(resData.data.data.gst_photo);
        setPin_code(resData.data.data.pin_code);
        setPostal_address(resData.data.data.address);
        // setGstNumber(resData.data.data.gst_number);
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
  }, []);

  return (
    <div>
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img
                    className="destop-view"
                    src={mobilelogo}
                    alt=""
                    style={{
                      width: "60%",
                      height: " 60%",
                      marginTop: "27%",
                      marginLeft: "18%",
                      objectFit: "contain",
                    }}
                  />
                  {/* <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  /> */}
                  <img
                    className="mobile-view size-n2"
                    src={mobilelogo}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section">
                  <div class="progress">
                    <div
                      class="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: "57.12%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div
                    className="fw-bold"
                    style={{ fontSize: "26px", marginBottom: "10px" }}
                  >
                    Company details
                  </div>
                  <form>
                    <div className="mb-3 destop-view">
                      <label htmlFor="exampleInputname" className="form-label">
                        Company Name
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        aria-describedby="name"
                        required
                        value={companyName}
                        onChange={handleCompanyNameChange}
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        aria-describedby="datetime"
                        placeholder="Company Name"
                        required
                        value={companyName}
                        onChange={handleCompanyNameChange}
                      />
                    </div>
                    {/* <div className="mb-3 destop-view">
                      <label htmlFor="cars">
                        Designation
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <select
                      className="form-select mb-3 form-control"
                      onChange={handleCategoryChange}
                      value={designation}
                    >
                      {designationArray.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        aria-describedby="datetime"
                        placeholder="Designation"
                        required
                        value={designation}
                        onChange={handleCategoryChange}
                      />
                    </div> */}
                    {/* <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        aria-describedby="datetime"
                        placeholder="Designation"
                        required
                        value={designation}
                        onChange={handleCategoryChange}
                      />
                    </div> */}
                    {/* <select
                      className="mb-3 form-control"
                      onChange={handleCategoryChange}
                      value={designation}
                    >
                      {designationArray.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select> */}
                    {/* <span className="bi bi-arrow-down-short"></span> */}
                    {/* <label htmlFor="cars">Profession</label>
                    <select
                      className="mb-3 form-control"
                      onChange={handleProfessionChange}
                      value={profession}
                    >
                      <option>Builder</option>
                      <option>Contractor</option>
                      <option>Plaster</option>
                      <option>Tile</option>
                      <option>Electrician</option>
                      <option>Plumber</option>
                      <option>POP</option>
                    </select> */}
                    <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Postal Address (For Sending you show badge)
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={postal_address}
                        onChange={(e) => setPostal_address(e.target.value)}
                      />
                    </div>
                    {/* <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        GST Number
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={gstNumber}
                        onBlur={handleBlur}
                        onChange={handleGstNumberChange}
                      />
                    </div> */}
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        placeholder=" Postal Address (For Sending you show badge)"
                        value={postal_address}
                        onChange={(e) => setPostal_address(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        City
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={city}
                        onChange={handleCitychange}
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        placeholder="City"
                        value={city}
                        onChange={handleCitychange}
                      />
                    </div>{" "}
                    <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        State
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={state}
                        onChange={handleStatechange}
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        placeholder="State"
                        value={state}
                        onChange={handleStatechange}
                      />
                    </div>
                    <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Pincode
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={pin_code}
                        onChange={handlePincodechange}
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        placeholder="pincode"
                        value={pin_code}
                        onChange={handlePincodechange}
                      />
                    </div>
                    {/* <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="GST number"
                        required
                        value={gstNumber}
                        onChange={handleGstNumberChange}
                        onBlur={handleBlur}
                      />
                    </div> */}
                    {/* <label htmlFor="cars">Deal In</label>
                    <select
                      className="mb-3 form-control"
                      onChange={handleDealInChange}
                      value={dealIn}
                    >
                      <option>Builder</option>
                      <option>Contractor</option>
                      <option>Plaster</option>
                      <option>Tile</option>
                      <option>Electrician</option>
                      <option>Plumber</option>
                      <option>POP</option>
                    </select> */}
                    {/* <div
                      action="/upload"
                      method="post"
                      encType="multipart/form-data"
                    >
                      <label>
                        Upload GST Certificate
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="fileUpload"
                        id="fileUpload"
                        onChange={handlePhotoChange}
                      />
                    </div> */}
                    {errorMessage && (
                      <div style={{ color: "red" }}>{errorMessage}</div>
                    )}
                    <div className="two-btn">
                      <button className="btn btn-primary btn-primary-01">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            // setDataShow(dataShow - 1);
                          }}
                        >
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                          Back
                        </a>
                      </button>
                      <button
                        className="btn btn-primary btn-primary-01"
                        onClick={handleContinue}
                      >
                        Continue
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                  <img className="fixedlogo" src={fixedlogo} alt="" />
                  {/* <div className="progress">
                    <div
                      class="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: "66.64%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissingDetailsOne;
