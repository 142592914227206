import React, { useEffect, useState } from "react";
// import logo from "../images/logo-01.png";
import logo from "../images/pmi-grand-h-logo.png";
import mobilelogo from "../images/pmi-grand-w-logo.png";
import fixedlogo from "../images/fixed-logo.png";
import rubiesImage from "../images/many-rubies-01.png";
import mobileViewHome from "../images/mobile-view-home-image.png";
import decImage from "../images/december-image.png";
import axios from "axios";
import { message } from "antd";
const FirstPage = ({
  setDataShow,
  dataShow,
  phoneNo,
  setPhoneNo,
  currentBuyer,
  setCurrentBuyer,
  reload,
  setReload,
  checkReload,
  setCheckReload,
}) => {
  const [name, setName] = useState("");
  const handelSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/add-number-in-list`, {
        mobile_number: phoneNo,
      })
      .then((resData) => {
        console.log(resData.data.otphaa);
        // setCheckReload(!checkReload);
        setDataShow(resData.data.status);
        // setDataShow(4);

        localStorage.setItem("barcode", resData.data.data.barcode);
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
  };
  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);

  const handleMobileNo = (e) => {
    if (/^\d{0,10}$/.test(e.target.value)) {
      setPhoneNo(e.target.value);
    }
  };

  // useEffect(() => {
  //   console.log(
  //     "asdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasdasdadadasddadasasasd");
  //   axios
  //     .post(`${process.env.REACT_APP_API_END_POINT}/get-detail-buyer`, {
  //       mobile_number: phoneNo,
  //     })
  //     .then((resData) => {
  //       if (resData.data.data) {
  //         console.log("hello i am here", resData.data.data);
  //         setCurrentBuyer(resData.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error:", error.message);
  //     });
  // }, [reload]);

  return (
    <div>
      {" "}
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img className="destop-view" src={logo} alt="" />
                  {/* <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  /> */}

                  {/* <!-- <img className="destop-view" src="../images/jjs-logo-image.png" alt=""> --> */}

                  <img
                    className="mobile-view size-n2"
                    src={mobilelogo}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section mt-5">
                  <div className="progress">
                    <div
                      className="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: " 14.28%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  {/* <!-- <div className="right-logo">
                                <img className="destop-view" src="../images/logo-01.png">
                            </div> --> */}
                  {/* <p className="mobile-none">
                  <img className="destop-view" src={decImage} alt="" />
                    jecc, jaipur
                    <br /> 20-23 december'24
                  </p> */}
                  <a href="#" className="resister-tag mt-5 pt-5">
                    Register online for the show
                  </a>
                </div>
                <form onSubmit={handelSubmit}>
                  {/* <div className="mb-3 destop-view">
                    <label for="exampleInputname" className="form-label ">
                      Full Name
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      id="exampleInputName"
                      aria-describedby="name"
                    />
                   
                  </div> */}
                  <div className="mb-3 destop-view">
                    <label for="exampleInputPassword1" className="form-label">
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      value={phoneNo}
                      onChange={handleMobileNo}
                      id="exampleInputPassword1"
                      pattern="[0-9]*"
                      maxlength="10"
                      title="Please enter a 10-digit phone number"
                      required
                    />
                  </div>
                  {/* <div className="mb-3 mobile-view">
                    <input
                      type="name"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="exampleInputName"
                      aria-describedby="name"
                      placeholder="Full Name"
                      required
                    />
                  </div> */}
                  <div className="mb-3 mobile-view">
                    <input
                      type="tel"
                      className="form-control"
                      value={phoneNo}
                      onChange={handleMobileNo}
                      id="exampleInputPassword1"
                      pattern="[0-9]*"
                      maxlength="10"
                      title="Please enter a 10-digit phone number"
                      required
                    />
                  </div>
                  {/* <!-- <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1">
                                <label className="form-check-label" for="exampleCheck1">Check me out</label>
                            </div> --> */}
                  <button className="btn btn-primary" type="submit">
                    <a>
                      Continue
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </a>
                  </button>
                </form>
                <img className="fixedlogo" src={fixedlogo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
