function LoginRedirection(res, routevar) {
    if (
      res.data.message ===
      "Token Expired: The authentication token you provided has expired. To continue using our services, please renew your token by following our authentication or login process. If you encounter any issues, feel free to contact our support team for assistance."
    ) {
      localStorage.removeItem("jff_token");
      localStorage.removeItem("category");
      localStorage.removeItem("permission");
      window.location.href = `${process.env.REACT_APP_LOGIN_REDIRECTION_URL}/${routevar}`;
    }
  }
  
  export default LoginRedirection;
  