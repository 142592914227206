import React, { useEffect, useRef, useState } from "react";

// import logo from "../images/logo-01.png";
import logo from "../images/pmi-grand-h-logo.png";
import mobilelogo from "../images/pmi-grand-w-logo.png";
import fixedlogo from "../images/fixed-logo.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/mobile-view-home-image.png";
import axios from "axios";
import { message } from "antd";
const VerifyOtp = ({
  setDataShow,
  dataShow,
  phoneNo,
  setPhoneNo,
  currentBuyer,
  setCurrentBuyer,
  reload,
  setReload,
  checkReload,
  setCheckReload,
}) => {
  const inputs = useRef([]);
  const [otp, setOtp] = useState("");

  //  useEffect(() => {
  //    inputs.current[0]?.focus();
  //  }, []);
  const handleInputChange = (index, e) => {
    const value = e.target.value;
    console.log(
      "otp",
      otp,
      "value",
      typeof value,
      value === "" && index > 0,
      value && index < 3 && value !== " ",
      value === " "
    );
    setOtp((prev) => prev + value);

    if (value === "" && index > 0) {
      // If the backspace key is pressed and the input is not the first one,
      // move focus to the previous input and remove the character.
      inputs.current[index - 1].focus();
      setOtp((prev) => prev.slice(0, prev.length - 1));
    } else if (value && index < 3 && value !== " ") {
      // If a character is entered and the input is not the last one,
      // move focus to the next input and append the character.
      inputs.current[index + 1].focus();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault(); // Prevent default behavior (adding space to input)
    }
  };

  const handleVerify = (e) => {
    e.preventDefault();
    console.log("verify Otp", otp, phoneNo);
    if (!otp) {
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/otp-varification`, {
        otp,
        barcode: localStorage.getItem("barcode"),
      })
      .then((resData) => {
        message.success(resData.data.message);

        if (resData.data.message === "OTP verified") {
          setCheckReload(!checkReload);
          setDataShow(4);
        } else if (resData.data.message === "all data aviliable") {
          console.log("varify otp", resData.data.data[0]);
          setCurrentBuyer(resData.data.data[0]);
          setDataShow(3);
        }
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
  };
  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);
  return (
    <div>
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img className="destop-view" src={logo} alt="" />
                  {/* <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  /> */}

                  {/* <!-- <img className="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                  <img
                    className="mobile-view mt-5"
                    src={logoWithRuby}
                    alt=""
                    style={{ width: "200px", height: "200px" }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section mt-5">
                  <div className="progress mt-5">
                    <div
                      className="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: "28.56%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <form id="nt-1">
                    <div className="content mt-5">
                      <h2>Enter your OTP</h2>
                      <div className="otp-input-box">
                        {[0, 1, 2, 3].map((index) => (
                          <input
                            key={index}
                            type="text"
                            name="otp"
                            className="otp-input"
                            maxLength="1"
                            required
                            onKeyDown={handleKeyDown}
                            ref={(el) => (inputs.current[index] = el)}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        ))}
                      </div>
                      {/* <div className="otp-input-box">
                            <input
                              type="text"
                              id="otpInput"
                              name="otp"
                              className="otp-input"
                              maxlength="1"
                              required
                            />
                            <input
                              type="text"
                              id="otpInput"
                              name="otp"
                              className="otp-input"
                              maxlength="1"
                              required
                            />
                            <input
                              type="text"
                              id="otpInput"
                              name="otp"
                              className="otp-input"
                              maxlength="1"
                              required
                            />
                            <input
                              type="text"
                              id="otpInput"
                              name="otp"
                              className="otp-input"
                              maxlength="1"
                              required
                            />
                          </div> */}
                    </div>
                    <button className="btn btn-primary" onClick={handleVerify}>
                      <a>Verify OTP</a>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
