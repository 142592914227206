import React, { useEffect, useState } from "react";
import axios from "axios";
import MainLogo from "../../../src/assets/image/main-logo.png";
import { useLocation } from "react-router-dom";
import Footer from "../superAdmin/footer/Footer";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import TokenUndefined from "../Service/TokenUndefined";
import { message } from "antd";
import FormatChecker from "../Service/FormatChecker";
import DatePickers from "../../common/DatePickers";
import DateTimerPicker from "../../common/DateTimerPicker";

function VisitorForm({
  formHeader,
  formName,
  editStatus,
  companyType,
  edit_name,
  edit_contact,
  edit_phone,
  edit_email,
  id,
  barcode,
  barcodeId,
}) {
  let editName = edit_name === undefined ? "" : edit_name;
  let editContact = edit_contact === undefined ? "" : edit_contact;
  let editPhone = edit_phone === undefined ? "" : edit_phone;
  let editEmail = edit_email === undefined ? "" : edit_email;

  console.log(
    "sdasdadadadadads0",
    editName,
    editContact,
    editPhone,
    editEmail,
    companyType
  );
  const navigate = useNavigate();

  let currentLocation = useLocation().pathname;

  const designationArray = [
    "Owner/propreitor",
    "Partner",
    "Director",
    "Procurement/Purchase head",
    "Other",
  ];
  const travelArray = ["Train", "Flight", "Self"];
  const visitingAsArray = [
    "Wholesaler",
    "Manufacturer",
    "Retailer",
    "Chain Store   ",
    "Agent/Broker",
  ];
  const [number, setNumber] = useState(editPhone);
  const [visitorName, setVisitorName] = useState(editContact);
  const [designation, setDesignation] = useState("");
  const [visitingCategory, setVisitingCategory] = useState("");
  const [visitingID, setVisitingID] = useState("");
  const [visiting_as, setVisiting_as] = useState("");
  const [yourCompany, setYourCompany] = useState();
  const [comapnyList, setComapnyList] = useState([]);
  const [invitedByCompany, setInvitedByCompany] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState(editEmail);
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressTwo, setstreetAddressTwo] = useState("");
  const [country, setCountry] = useState("India");
  const [hearAbout, setHearAbout] = useState("");
  const [photo, setPhoto] = useState();
  const [idProofFront, setIDProofFront] = useState();
  const [idProofBack, setIDProofBack] = useState();
  const [invitedCompanyName, setInvitedCompanyName] = useState(editName);
  const [visitingDay, setVisitingDay] = useState("");
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exhibitorName, setExhibitorName] = useState("");
  const [photoStatus, setPhotoStatus] = useState(false);
  const [idFrontStatus, setIdFrontStatus] = useState(false);
  const [idBackStatus, setIdBackStatus] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [onward_arrival, setOnward_arrival] = useState("");
  const [onward_departure, setOnward_departure] = useState("");
  const [travel_mode, setTravel_mode] = useState("Flight");
  const [date_and_time_of_arrival, setDate_and_time_of_arrival] = useState("");
  const [transport_name, setTransport_name] = useState("");
  const [transport_number, setTransport_number] = useState("");
  const [hotel_check_in_date, setHotel_check_in_date] = useState("");
  const [hotel_check_out_date, setHotel_check_out_date] = useState("");
  const [remark, setRemark] = useState("");
  const [return_arrival, setReturn_arrival] = useState("");
  const [return_departure, setReturn_departure] = useState("");
  const [return_travel_mode, setReturn_travel_mode] = useState("Flight");
  const [return_date_and_time_of_arrival, setReturn_date_and_time_of_arrival] =
    useState("");
  const [return_transport_name, setReturn_transport_name] = useState("");
  const [return_train_number, setReturn_train_number] = useState("");
  const [redirectToAddOn, setRedirectToAddOn] = useState(false);

  const handleMobileNoChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setNumber(numericValue);
  };

  let formJson = {
    visitor: [
      "mobile",
      "visitor_name",
      "designation",
      "visiting_as",
      // "invited_company",
      "email",
      "street_add_line1",
      "street_add_line2",
      "city",
      "state",
      "zipcode",
      "country",
      "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
    exhibitor_owner: [
      "mobile",
      "visitor_name",
      "designation",
      // "visiting_as",
      // "invited_company",
      "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "hotel_check_in",
      "hotel_check_out",
      "arrival_title",
      "arrival_city",
      "departure_city",
      "travel_mode",
      "arrival_date_time",
      "travel_mode_name",
      "travel_mode_number",
      "return_arrival_city",
      "return_departure_city",
      "return_travel_mode",
      "return_date_time",
      "return_mode_name",
      "return_mode_number",
      "return_title",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
    exhibitor_staff: [
      "mobile",
      "visitor_name",
      "designation",
      // "visiting_as",
      // "invited_company",
      "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "hotel_check_in",
      "hotel_check_out",
      "arrival_title",
      "arrival_city",
      "departure_city",
      "travel_mode",
      "arrival_date_time",
      "travel_mode_name",
      "travel_mode_number",
      "return_arrival_city",
      "return_departure_city",
      "return_travel_mode",
      "return_date_time",
      "return_mode_name",
      "return_mode_number",
      "return_title",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
    exhibitor_modal: ["mobile", "visitor_name", "photo", "id_front", "id_back"],
    exhibitor_family: [
      "mobile",
      "visitor_name",
      "photo",
      "id_front",
      "id_back",
    ],
    exhibitor_vip: [
      "mobile",
      "visitor_name",
      "designation",
      "visiting_as",
      "invited_company",
      "email",
      "street_add_line1",
      "street_add_line2",
      "city",
      "state",
      "zipcode",
      "country",
      "hduhear",
      "photo",
      "id_front",
      "id_back",
      "visiting_day",
      "company_name",
    ],
    vendor_owner: [
      "mobile",
      "visitor_name",
      // "designation",
      // "visiting_as",
      // "invited_company",
      // "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
    edit_update: [
      "visitor_name",
      "mobile",
      "invited_company",
      "email",
      "photo",
      "id_front",
      "id_back",
    ],
    edit_form_update: [
      "visitor_name",
      "mobile",
      "invited_company",
      "email",
      "photo",
      "id_front",
      "id_back",
    ],
    vendor_staff: [
      "mobile",
      "visitor_name",
      "designation",
      // "visiting_as",
      // "invited_company",
      "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
  };
  function handlePhoto(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setPhotoStatus(dataStatus);
    if (!dataStatus) {
      setPhoto(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }
  const handleName = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setVisitorName(alphabetOnlyValue);
  };
  const handleCity = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCity(alphabetOnlyValue);
  };
  const handleState = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setState(alphabetOnlyValue);
  };
  const handleCountry = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCountry(alphabetOnlyValue);
  };

  const handlePostalCode = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setPostalCode(numericValue);
  };
  // useEffect(() => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_END_POINT}/visitor-category`,
  //       {
  //         id: visitingID,
  //       },
  //       {
  //         headers: {
  //           token: localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log("get-visitor", res.data.data);
  //       setAllCategory(res.data.data);
  //     });
  // }, []);

  function handleIDFront(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setIdFrontStatus(dataStatus);
    if (!dataStatus) {
      setIDProofFront(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }
  function handleIDBack(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setIdBackStatus(dataStatus);
    if (!dataStatus) {
      setIDProofBack(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!emailPattern.test(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    emptyField();
  }, [reload]);

  useEffect(() => {
    if (
      formName === "vendor_owner" ||
      formName === "vendor_staff" ||
      companyType === "vendor"
    ) {
      axios
        .get(`${process.env.REACT_APP_API_END_POINT}/get-vendor-list`)
        .then((res) => {
          // console.log("assasdasaadads", res.data.data);
          setComapnyList(res.data.data);
        });
    } else {
      if (formName !== "vendor_staff" && formName !== "vendor_owner") {
        axios
          .get(`${process.env.REACT_APP_API_END_POINT}/get-exhibitor-list`, {
            headers: {
              token: localStorage.getItem("jff_token"),
            },
          })
          .then((res) => {
            // console.log("assasdasaadads", res.data.data);
            setInvitedByCompany(res.data.data);
          });
      }
    }
  }, [reload]);

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (formName !== "vendor_staff" && formName !== "vendor_owner") {
      console.log(
        "abcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadadd",
        formName !== "vendor_staff" && formName !== "vendor_owner",
        formName
      );
      TokenUndefined("exhibitor/login");
    }

    if (formName !== "vendor_staff" && formName !== "vendor_owner") {
      axios
        .post(`${process.env.REACT_APP_API_END_POINT}/get-exhibitor-name`, "", {
          headers: {
            token: localStorage.getItem("jff_token"),
          },
        })
        .then((res) => {
          // LoginRedirection(res, "exhibitor/login");
          setExhibitorName(res.data.data);
        })
        .catch((err) => {
          // LoginRedirection("/superadmin/login");

          // if (formName !== "vendor_staff" || formName !== "vendor_owner") {
          if (err.response.status === 401) {
            navigate("/exhibitor/login");
            localStorage.removeItem("jff_token");
            localStorage.removeItem("category");
            localStorage.removeItem("permission");
            localStorage.removeItem("user_id");
            localStorage.removeItem("type_of_user");
          }
          // }
        });
    }
  }, []);

  const handleSubmit = (statusToShow) => {
    setIsLoading(true);
    if (!visitorName) {
      message.error("Name is required");
      setIsLoading(false);
    } else if (!number) {
      message.error("Phone Number is required");
      setIsLoading(false);
    } else if (number.length !== 10) {
      message.error("Please Enter 10 Digit Mobile Number");
      setIsLoading(false);
      return;
    } else if (emailError) {
      message.error("Invalid email format");
      setIsLoading(false);
      return;
    } else if (!companyName && formName === "exhibitor_vip") {
      message.error("Company Name is required");
      setIsLoading(false);
    } else if (!designation && formName === "exhibitor_vip") {
      message.error("Designation is required");
      setIsLoading(false);
    } else if (!city && formName === "exhibitor_vip") {
      message.error("City is required");
      setIsLoading(false);
    } else if (!country && formName === "exhibitor_vip") {
      message.error("Country is required");
      setIsLoading(false);
    } else if (!photo) {
      message.error("Photo is required");
      setIsLoading(false);
    } else if (!idProofFront) {
      message.error("ID Proof Front is required");
      setIsLoading(false);
    } else if (!idProofBack) {
      message.error("ID Proof Back is required");
      setIsLoading(false);
    } else {
      if (editStatus) {
        // if (number.length !== 10) {
        //   message.error("Please Enter 10 Digit Mobile Number");
        //   setIsLoading(false);
        //   return;
        // }
        // if (emailError) {
        //   message.error("Invalid email format");
        //   setIsLoading(false);
        //   return;
        // }
        // e.preventDefault();
        const formData = new FormData();
        formData.append("visitor_name", visitorName);
        formData.append("email", email);
        formData.append("company_name", companyName);
        formData.append("mobile_number", number);
        formData.append("invited_exhibitor_id", invitedByCompany);
        formData.append("photo", photo);
        formData.append("id_proof_front", idProofFront);
        formData.append("id_proof_back", idProofBack);
        formData.append("id", id);
        formData.append("onward_arrival", onward_arrival);
        formData.append("onward_departure", onward_departure);
        formData.append("travel_mode", travel_mode);
        formData.append("date_and_time_of_arrival", date_and_time_of_arrival);
        formData.append("transport_name", transport_name);
        formData.append("transport_number", transport_number);
        formData.append("hotel_check_in_date", hotel_check_in_date);
        formData.append("hotel_check_out_date", hotel_check_out_date);
        formData.append("remark", remark);
        formData.append("return_arrival", return_arrival);
        formData.append("return_departure", return_departure);
        formData.append("return_travel_mode", return_travel_mode);
        formData.append(
          "return_date_and_time_of_arrival",
          return_date_and_time_of_arrival
        );
        formData.append("return_transport_name", return_transport_name);
        formData.append("return_train_number", return_train_number);

        axios
          .post(
            `${process.env.REACT_APP_API_END_POINT}/update-visitor`,
            formData,
            {
              headers: {
                token: `${localStorage.getItem("jff_token")}`,
                barcode,
              },
            }
          )
          .then((res) => {
            if (res.data.messageData) {
              message.error(res.data.messageData);
              setIsLoading(false);
              emptyField();
            } else {
              window.location.reload(true);
              // setReload(!reload)
              // message.success("Form Submit Successfully");
              window.alert("Form Submit Successfully");
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.error("Axios Error:", error);
          });
      } else {
        // {
        if (!photo && !idProofFront && !idProofBack) {
          axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/add-visitor`,
              {
                mobile_number: number,
                visitor_name: visitorName,
                designation: designation,
                visiting_as: visiting_as,
                firm_id: yourCompany,
                invited_exhibitor_id: invitedByCompany,
                email: email,
                company_name: companyName,
                street_address: streetAddress,
                street_address_two: streetAddressTwo,
                city: city,
                state: state,
                postal_code: postalCode,
                country: country,
                hear_about_jff: hearAbout,
                category: formName,
                visiting_day: visitingDay,
                visitor_category_id: visitingID,
                barcodeId,
              },
              {
                headers: {
                  token: localStorage.getItem("jff_token"),
                },
              }
            )
            .then((res) => {
              // setReload(!reload)
              // window.location.reload(true);
              // message.success("Form Submit Successfully");
              if (res.data.messageData) {
                message.error(res.data.messageData);
                setIsLoading(false);
                emptyField();
                message.error("Please Upload All Photos Again");
              } else {
                window.alert("Form Submit Successfully");
                setIsLoading(false);
                window.location.reload(true);
                if (formName === "exhibitor_owner") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/owner-list");
                } else if (formName === "exhibitor_staff") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/staff-list");
                } else if (formName === "exhibitor_modal") {
                  navigate("/exhibitor/modal-list");
                } else if (formName === "exhibitor_family") {
                  navigate("/exhibitor/family-list");
                } else if (formName === "exhibitor_vip") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/vip-guest-requrest-list");
                }
              }
            })
            .catch((err) => {
              // LoginRedirection("/superadmin/login");
              if (err.response.status === 401) {
                navigate("/superadmin/login");
                localStorage.removeItem("jff_token");
                localStorage.removeItem("category");
                localStorage.removeItem("permission");
                localStorage.removeItem("user_id");
                localStorage.removeItem("type_of_user");
              }
            });
        } else {
          const formData = new FormData();
          formData.append("mobile_number", number);
          formData.append("visitor_name", visitorName);
          formData.append("designation", designation);
          formData.append("visiting_as", visiting_as);
          formData.append("firm_id", yourCompany);
          formData.append("invited_exhibitor_id", invitedByCompany);
          formData.append("email", email);
          formData.append("company_name", companyName);
          formData.append("street_address", streetAddress);
          formData.append("street_address_two", streetAddressTwo);
          formData.append("city", city);
          formData.append("state", state);
          formData.append("postal_code", postalCode);
          formData.append("country", country);
          formData.append("hear_about_jff", hearAbout);
          formData.append("photo", photo);
          formData.append("id_proof_front", idProofFront);
          formData.append("id_proof_back", idProofBack);
          formData.append("category", formName);
          formData.append("visiting_day", visitingDay);
          formData.append("visitor_category_id", visitingID);
          formData.append("barcodeId", barcodeId);
          formData.append("onward_arrival", onward_arrival);
          formData.append("onward_departure", onward_departure);
          formData.append("travel_mode", travel_mode);
          formData.append("date_and_time_of_arrival", date_and_time_of_arrival);
          formData.append("transport_name", transport_name);
          formData.append("transport_number", transport_number);
          formData.append("hotel_check_in_date", hotel_check_in_date);
          formData.append("hotel_check_out_date", hotel_check_out_date);
          formData.append("remark", remark);
          formData.append("return_arrival", return_arrival);
          formData.append("return_departure", return_departure);
          formData.append("return_travel_mode", return_travel_mode);
          formData.append(
            "return_date_and_time_of_arrival",
            return_date_and_time_of_arrival
          );
          formData.append("return_transport_name", return_transport_name);
          formData.append("return_train_number", return_train_number);

          axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/add-visitor`,
              formData,
              {
                headers: {
                  token: localStorage.getItem("jff_token"),
                },
              }
            )
            .then((res) => {
              // setReload(!reload)
              // window.location.reload(true);
              // message.success("Form Submit Successfully");

              if (res.data.messageData) {
                alert(res.data.messageData);
                window.location.reload(true);
                setIsLoading(false);
              } else {
                if (res.data.error) {
                  message.error(res.data.error);
                } else {
                  message.success("Form Submit Successfully");
                }
                setIsLoading(false);
                // window.location.reload(true);

                // console.log("anisdsd", formName);
                if (formName === "exhibitor_owner" && statusToShow === "true") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/exhibitor-staff-registration");
                } else if (formName === "exhibitor_owner") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/exhibitor-owner-registration");
                  window.location.reload(true);
                } else if (formName === "exhibitor_staff") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/staff-list");
                } else if (formName === "exhibitor_modal") {
                  navigate("/exhibitor/modal-list");
                } else if (formName === "exhibitor_family") {
                  navigate("/exhibitor/family-list");
                } else if (formName === "exhibitor_vip") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/vip-guest-requrest-list");
                }
              }
            })
            .catch((err) => {
              // LoginRedirection("/superadmin/login");

              if (err.response.status === 401) {
                navigate("/superadmin/login");
                localStorage.removeItem("jff_token");
                localStorage.removeItem("category");
                localStorage.removeItem("permission");
                localStorage.removeItem("user_id");
                localStorage.removeItem("type_of_user");
              }
            });
        }
        // }
      }
    }
  };
  const emptyField = () => {
    setVisitorName("");
    setNumber("");
    setEmail("");
    setCompanyName("");
    setDesignation("");
    setYourCompany("");
    setVisiting_as("");
    setStreetAddress("");
    setstreetAddressTwo("");
    setCity("");
    setState("");
    setPostalCode("");
    // setCountry("");
    setHearAbout("");
    setPhoto("");
    setIDProofFront("");
    setIDProofBack("");
    setInvitedByCompany("");
    setVisitingCategory("");
    setVisitingID("");
    setInvitedCompanyName("");
    setVisitingDay("");
  };
  return (
    <>
      <>
        {/* <img
          src={MainLogo}
          style={{ maxWidth: "150px" }}
          className="m-2"
          alt=""
        /> */}
        {/* <div
          className="text-center py-2 d-flex justify-content-between"
          // style={{ backgroundColor: "rgb(61, 65, 86)" }}
        > */}
        <div className="text-center">
          <h4 className="form-heading">{formHeader}</h4>
        </div>

        <section>
          <div className="container">
            <div className="form-body-section">
              <div className="form-body">
                <div className="row">
                  {formJson[formName].includes("visitor_name") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Name <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Name"
                        value={visitorName}
                        maxLength={100}
                        onChange={(e) => {
                          handleName(e);
                        }}
                      />
                    </div>
                  )}
                  {formJson[formName].includes("mobile") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Mobile No. <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Mobile No."
                        value={number}
                        maxLength={10}
                        onChange={handleMobileNoChange}
                      />
                    </div>
                  )}
                  {formJson[formName].includes("email") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">Email</label>
                      <input
                        className="form-control my-2"
                        placeholder="Email"
                        onChange={(e) => {
                          handleEmailChange(e);
                        }}
                      />
                    </div>
                  )}
                  {formJson[formName].includes("company_name") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Company Name <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Company Name "
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  {/* {formJson[formName].includes("designation") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>Designation</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {designation ? designation : "Select designation"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {designationArray?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data}
                          onClick={() => setDesignation(data)}
                          className="d-flex justify-content-center"
                        >
                          {data}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                  {/* {formJson[formName].includes("designation") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Designation <font color="#FF0000">*</font>
                      </label>
                      <Select
                        className="my-2"
                        options={designationArray.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        value={
                          designation
                            ? { value: designation, label: designation }
                            : null
                        }
                        onChange={(selectedOption) =>
                          setDesignation(
                            selectedOption ? selectedOption.value : null
                          )
                        }
                        isSearchable
                        placeholder="Select designation"
                      />
                    </div>
                  )} */}
                  {formJson[formName].includes("designation") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Designation <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Designation"
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                        maxLength={25}
                      />
                    </div>
                  )}
                  {/* {formJson[formName].includes("visiting_as") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>You are visiting show as</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {visiting_as ? visiting_as : "Select visiting as"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {visitingAsArray?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data}
                          onClick={() => setVisiting_as(data)}
                          className="d-flex justify-content-center"
                        >
                          {data}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                  {formJson[formName].includes("visiting_as") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        You are visiting show as
                      </label>
                      <Select
                        className="my-2"
                        options={visitingAsArray.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        value={
                          visiting_as
                            ? { value: visiting_as, label: visiting_as }
                            : null
                        }
                        onChange={(selectedOption) =>
                          setVisiting_as(
                            selectedOption ? selectedOption.value : null
                          )
                        }
                        isSearchable
                        placeholder="Select visiting as"
                      />
                    </div>
                  )}
                  {formJson[formName].includes("your_company") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label>Your Company</label>
                      <input
                        className="form-control my-2"
                        placeholder="Your Company"
                        value={yourCompany}
                        onChange={(e) => {
                          setYourCompany(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  {/* {formJson[formName].includes("invited_company") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>Name of the company that invited you</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {invitedCompanyName
                        ? invitedCompanyName
                        : "Select visiting the show as"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {comapnyList?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data.company_name}
                          onClick={() => {
                            setInvitedByCompany(data._id);
                            setInvitedCompanyName(data.company_name);
                          }}
                          className="d-flex justify-content-center"
                        >
                          {data.company_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                  {/* {formJson[formName].includes("invited_company") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label className="fw-bold mb-2">
                    Name of the company that invited you
                  </label>
                  <Select
                    options={comapnyList.map((data) => ({
                      value: data.company_name,
                      label: data.company_name,
                    }))}
                    value={
                      invitedCompanyName
                        ? {
                            value: invitedCompanyName,
                            label: invitedCompanyName,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      const selectedCompany = comapnyList.find(
                        (company) =>
                          company.company_name === selectedOption.value
                      );
                      setInvitedByCompany(
                        selectedCompany ? selectedCompany._id : null
                      );
                      setInvitedCompanyName(
                        selectedOption ? selectedOption.value : null
                      );
                    }}
                    isSearchable
                    placeholder="Select the company"
                  />
                </div>
              )} */}
                  {/* {formJson[formName].includes("  ") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label className="fw-bold mb-2">
                    Name of the company that invited you
                  </label>
                  <Select
                    options={comapnyList.map((data) => ({
                      value: data.company_name,
                      label: data.company_name,
                      isDisabled: data.company_name === exhibitorName, // Disable if the value matches exhibitorName
                      isSelected: data.company_name === exhibitorName, // Select if the value matches exhibitorName
                    }))}
                    value={
                      exhibitorName
                        ? {
                            value: exhibitorName,
                            label: exhibitorName,
                            isDisabled: true, // Disable the selected option
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      const selectedCompany = comapnyList.find(
                        (company) =>
                          company.company_name === selectedOption.value
                      );
                      setInvitedByCompany(
                        selectedCompany ? selectedCompany._id : null
                      );
                      setInvitedCompanyName(
                        selectedOption ? selectedOption.value : null
                      );
                    }}
                    isSearchable
                    placeholder="Select the company"
                  />
                </div>
              )} */}
                  {formJson[formName].includes("street_add_line2") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">Address</label>
                      <input
                        className="form-control my-2"
                        placeholder="Address"
                        onChange={(e) => {
                          setStreetAddress(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  {/* {formJson[formName].includes("street_add_line2") && (
                <div className="form-group col-lg-6 col-md-6 -">
                  <label className="fw-bold">Street Address Line 2</label>
                  <input
                    className="form-control my-2"
                    placeholder="Street Address Line 2"
                    onChange={(e) => {
                      setstreetAddressTwo(e.target.value);
                    }}
                  />
                </div>
              )} */}
                  {formJson[formName].includes("city") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        City <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="City"
                        onChange={(e) => {
                          handleCity(e);
                        }}
                        maxLength={25}
                      />
                    </div>
                  )}
                  {formJson[formName].includes("state") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">State</label>
                      <input
                        className="form-control my-2"
                        placeholder="State"
                        onChange={(e) => {
                          handleState(e);
                        }}
                        maxLength={25}
                      />
                    </div>
                  )}
                  {formJson[formName].includes("zipcode") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">Postal Zip Code</label>
                      <input
                        className="form-control my-2"
                        placeholder="Postal Zip Code"
                        onChange={(e) => {
                          handlePostalCode(e);
                        }}
                        maxLength={6}
                      />
                    </div>
                  )}
                  {formJson[formName].includes("country") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Country <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        value={country}
                        placeholder="Country"
                        onChange={(e) => {
                          handleCountry(e);
                        }}
                      />
                    </div>
                  )}
                  {formJson[formName].includes("hotel_check_out") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Remark <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Remark"
                        value={remark}
                        maxLength={100}
                        onChange={(e) => {
                          setRemark(e.target.value);
                        }}
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("photo") && (
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="fw-bold">
                          Upload Photo <font color="#FF0000">*</font>
                          {photoStatus && (
                            <span className="photo-error">
                              Only supported (JPG/JPEG/PNG)
                            </span>
                          )}
                        </label>

                        <input
                          type="file"
                          onChange={handlePhoto}
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                        />
                      </div>
                    </div>
                  )}
                  {formJson[formName].includes("id_front") && (
                    <div className="col-lg-6 col-md-6 ">
                      <div className="form-group">
                        <label className="fw-bold">
                          ID Proof(front) <font color="#FF0000">*</font>
                          {idFrontStatus && (
                            <span className="photo-error">
                              Only supported (JPG/JPEG/PNG)
                            </span>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={handleIDFront}
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                        />
                      </div>
                    </div>
                  )}
                  {formJson[formName].includes("id_back") && (
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="fw-bold">
                          ID Proof(back) <font color="#FF0000">*</font>
                          {idBackStatus && (
                            <span className="photo-error">
                              Only supported (JPG/JPEG/PNG)
                            </span>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={handleIDBack}
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                        />
                      </div>
                    </div>
                  )}
                  {formJson[formName].includes("hotel_check_in") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Hotel Check-in Date <font color="#FF0000">*</font>
                      </label>
                      <DatePickers
                        setDate={setHotel_check_in_date}
                        lable={"Check-in date"}
                      />
                      {/* <input
                        className="form-control my-2"
                        placeholder="Hotel Check-in Date"
                        value={hotel_check_in_date}
                        maxLength={100}
                        onChange={(e) => {
                          setHotel_check_in_date(e.target.value);
                        }}
                      /> */}
                    </div>
                  )}{" "}
                  {formJson[formName].includes("hotel_check_in") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Hotel Check-out Date <font color="#FF0000">*</font>
                      </label>
                      <DatePickers
                        setDate={setHotel_check_out_date}
                        lable={"Check-out date"}
                      />
                      {/* <input
                        className="form-control my-2"
                        placeholder="Hotel Check-out Date"
                        value={hotel_check_out_date}
                        maxLength={100}
                        onChange={(e) => {
                          setHotel_check_out_date(e.target.value);
                        }}
                      /> */}
                    </div>
                  )}{" "}
                  {/* {formJson[formName].includes("hduhear") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label className="fw-bold">How do you hear about</label>
                  <input
                    className="form-control my-2"
                    placeholder="How do you hear about"
                    onChange={(e) => {
                      setHearAbout(e.target.value);
                    }}
                  />
                </div>
              )} */}
                  {/* 
              {formJson[formName].includes("visitor_category") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>Category</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {visitingCategory ? visitingCategory : "Select category"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {allCategory?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data.category_name}
                          onClick={() => {
                            setVisitingCategory(data.category_name);
                            setVisitingID(data._id);
                          }}
                          className="d-flex justify-content-center"
                        >
                          {data.category_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                  {formJson[formName].includes("arrival_title") && (
                    <div className="mt-1">
                      {" "}
                      <hr></hr>
                      <div className="text-center">
                        <h2 className="text-danger">Arrival Detail</h2>
                      </div>
                    </div>
                  )}
                  {formJson[formName].includes("arrival_city") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Arrival (City) <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Arrival (City)"
                        value={onward_arrival}
                        maxLength={100}
                        onChange={(e) => {
                          setOnward_arrival(e.target.value);
                        }}
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("departure_city") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Departure (City) <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Departure (City)"
                        value={onward_departure}
                        maxLength={100}
                        onChange={(e) => {
                          setOnward_departure(e.target.value);
                        }}
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("travel_mode") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Travel Mode <font color="#FF0000">*</font>
                      </label>
                      <Select
                        className="my-2"
                        options={travelArray.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        value={
                          travel_mode
                            ? { value: travel_mode, label: travel_mode }
                            : null
                        }
                        onChange={(selectedOption) =>
                          setTravel_mode(
                            selectedOption ? selectedOption.value : null
                          )
                        }
                        isSearchable
                        placeholder="Select designation"
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("arrival_date_time") &&
                    travel_mode !== "Self" && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Date And Time of Arrival{" "}
                          <font color="#FF0000">*</font>
                        </label>
                        <DateTimerPicker
                          setTime={setDate_and_time_of_arrival}
                          time={date_and_time_of_arrival}
                        />
                        {/* <input
                          className="form-control my-2"
                          placeholder="Date And Time of Arrival"
                          value={date_and_time_of_arrival}
                          maxLength={100}
                          onChange={(e) => {
                            setDate_and_time_of_arrival(e.target.value);
                          }}
                        /> */}
                      </div>
                    )}{" "}
                  {formJson[formName].includes("travel_mode_name") &&
                    travel_mode !== "Self" && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          {travel_mode} name <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder={`${travel_mode} name`}
                          value={transport_name}
                          maxLength={100}
                          onChange={(e) => {
                            setTransport_name(e.target.value);
                          }}
                        />
                      </div>
                    )}{" "}
                  {formJson[formName].includes("travel_mode_number") &&
                    travel_mode !== "Self" && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          {travel_mode} Number <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder={`${travel_mode} Number`}
                          value={transport_number}
                          maxLength={100}
                          onChange={(e) => {
                            setTransport_number(e.target.value);
                          }}
                        />
                      </div>
                    )}{" "}
                  {formJson[formName].includes("return_title") && (
                    <div className="mt-1">
                      <hr></hr>
                      <div className="text-center">
                        <h2 className="text-danger">Return Detail</h2>
                      </div>
                    </div>
                  )}
                  {formJson[formName].includes("return_arrival_city") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Return Arrival (City) <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Return Arrival (City)"
                        value={return_arrival}
                        maxLength={100}
                        onChange={(e) => {
                          setReturn_arrival(e.target.value);
                        }}
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("return_departure_city") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Return Departure (City) <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Return Departure (City)"
                        value={return_departure}
                        maxLength={100}
                        onChange={(e) => {
                          setReturn_departure(e.target.value);
                        }}
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("return_travel_mode") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Return Travel Mode <font color="#FF0000">*</font>
                      </label>
                      <Select
                        className="my-2"
                        options={travelArray.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        value={
                          return_travel_mode
                            ? {
                                value: return_travel_mode,
                                label: return_travel_mode,
                              }
                            : null
                        }
                        onChange={(selectedOption) =>
                          setReturn_travel_mode(
                            selectedOption ? selectedOption.value : null
                          )
                        }
                        isSearchable
                        placeholder="Select designation"
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("return_date_time") &&
                    return_travel_mode !== "Self" && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Return Date And Time of Arrival{" "}
                          <font color="#FF0000">*</font>
                        </label>
                        <DateTimerPicker
                          setTime={setReturn_date_and_time_of_arrival}
                          time={return_date_and_time_of_arrival}
                        />
                        {/* <input
                          className="form-control my-2"
                          placeholder="Return Date And Time of Arrival"
                          value={return_date_and_time_of_arrival}
                          maxLength={100}
                          onChange={(e) => {
                            setReturn_date_and_time_of_arrival(e.target.value);
                          }}
                        /> */}
                      </div>
                    )}{" "}
                  {formJson[formName].includes("return_mode_name") &&
                    return_travel_mode !== "Self" && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Return {return_travel_mode} name{" "}
                          <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder={`Return ${return_travel_mode} name`}
                          value={return_transport_name}
                          maxLength={100}
                          onChange={(e) => {
                            setReturn_transport_name(e.target.value);
                          }}
                        />
                      </div>
                    )}{" "}
                  {formJson[formName].includes("return_mode_number") &&
                    return_travel_mode !== "Self" && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Return {return_travel_mode} Number{" "}
                          <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder={`Return ${return_travel_mode} Number`}
                          value={return_train_number}
                          maxLength={100}
                          onChange={(e) => {
                            setReturn_train_number(e.target.value);
                          }}
                        />
                      </div>
                    )}{" "}
                  {/* {formJson[formName].includes("visitor_name") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Name <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Name"
                        value={visitorName}
                        maxLength={100}
                        onChange={(e) => {
                          handleName(e);
                        }}
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("visitor_name") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Name <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Name"
                        value={visitorName}
                        maxLength={100}
                        onChange={(e) => {
                          handleName(e);
                        }}
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("visitor_name") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Name <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Name"
                        value={visitorName}
                        maxLength={100}
                        onChange={(e) => {
                          handleName(e);
                        }}
                      />
                    </div>
                  )}{" "}
                  {formJson[formName].includes("visitor_name") && (
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Name <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Name"
                        value={visitorName}
                        maxLength={100}
                        onChange={(e) => {
                          handleName(e);
                        }}
                      />
                    </div>
                  )} */}
                  {/* {formJson[formName].includes("visiting_day") && (
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label className="fw-bold">Visiting day</label>
                    <div className="d-flex my-2">
                      <div className="form-check me-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="day_one"
                          onClick={(e) => {
                            setVisitingDay(e.target.id);
                            console.log("assadadasdad", e.target.id);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Day One
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="day_two"
                          onClick={(e) => {
                            setVisitingDay(e.target.id);
                            console.log("assadadasdad", e.target.id);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          Day Two
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
                  <div className="d-flex justify-content-center">
                    {formName === "exhibitor_owner" && (
                      <div className="form-submit me-1">
                        <Button
                          onClick={() => {
                            handleSubmit("true");
                            setRedirectToAddOn(true);
                          }}
                          variant="outline-secondary"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Add On"
                          )}
                        </Button>
                      </div>
                    )}
                    <div className="form-submit ms-1">
                      <Button
                        onClick={() => {
                          handleSubmit("false");
                          setRedirectToAddOn(false);
                        }}
                        variant="outline-secondary"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Footer />
    </>
  );
}

export default VisitorForm;
