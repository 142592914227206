import React from "react";
import MainRoute from "./MainRoute";

const DefaultLayout = () => {
  return (
    <>
      <MainRoute />
    </>
  );
};

export default DefaultLayout;
