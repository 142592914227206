import React, { useEffect, useState } from "react";
// import logo from "../images/logo-01.png";
import logo from "../images/pmi-grand-h-logo.png";
import mobilelogo from "../images/pmi-grand-w-logo.png";
import fixedlogo from "../images/fixed-logo.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import axios from "axios";
import DatePicker from "../../../common/DatePickers";

import { message } from "antd";
import DateTimerPicker from "../../../common/DateTimerPicker";
const TravelInforamtion = ({
  setDataShow,
  dataShow,
  currentBuyer,
  setCurrentBuyer,
  reload,
  setReload,
}) => {
  const [arival_city, setArival_city] = useState("");
  const [travel_mode, setTravel_mode] = useState("Train");
  const [arival_pnr, setArival_pnr] = useState("");
  const [arival_date_time, setArival_date_time] = useState("");
  const [train_flight_name, setTrain_flight_name] = useState("");
  const [train_flight_number, setTrain_flight_number] = useState("");
  const [ticket_photo, setTicket_photo] = useState(null);
  const [remark, setRemark] = useState("");
  const [hotel_check_in_date, setHotel_check_in_date] = useState("");
  const [hotel_check_out_date, setHotel_check_out_date] = useState("");

  const [departure_city, setDeparture_city] = useState("");
  const travel_mode_array = ["Train", "Flight", "Self"];
  const [errorMessage, setErrorMessage] = useState("");

  const handleContinue = (e) => {
    e.preventDefault();

    // if (
    //   travel_mode !== "Self" &&
    //   (!arival_city ||
    //     !travel_mode ||
    //     !departure_city ||
    //     // !arival_pnr ||
    //     !arival_date_time ||
    //     !train_flight_name ||
    //     !train_flight_number ||
    //     !hotel_check_in_date ||
    //     !hotel_check_out_date)
    //   // !ticket_photo)
    // ) {
    //   setErrorMessage("Please fill in all fields");
    //   return;
    // }
    // if (
    //   travel_mode === "Self" &&
    //   (!travel_mode ||
    //     !arival_city ||
    //     !hotel_check_in_date ||
    //     !hotel_check_out_date)
    // ) {
    //   setErrorMessage("Please fill in all fields");
    // }

    const formData = new FormData();
    formData.append("arival_city", arival_city);
    formData.append("departure_city", departure_city);

    formData.append("travel_mode", travel_mode);
    // formData.append("arival_pnr", arival_pnr);
    formData.append("arival_date_time", arival_date_time);
    formData.append("train_flight_name", train_flight_name);
    formData.append("train_flight_number", train_flight_number);
    formData.append("hotel_check_in_date", hotel_check_in_date);
    formData.append("hotel_check_out_date", hotel_check_out_date);
    formData.append("visitor_remark", remark);
    // formData.append("travel_ticket", ticket_photo);
    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/add-buyer-travel`,
        formData,
        {
          headers: {
            barcode: localStorage.getItem("barcode"),
          },
        }
      )
      .then((resData) => {
        message.success(resData.data.message);

        // localStorage.setItem("barcode", resData.data.data.barcode);
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
    setDataShow(dataShow + 1);
  };

  useEffect(() => {
    localStorage.setItem("progress", dataShow);
    console.log("assdsasdasdasdasd", arival_date_time);
  }, [dataShow, arival_date_time]);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/get-detail-buyer`, "", {
        headers: {
          barcode: localStorage.getItem("barcode"),
        },
      })
      .then((resData) => {
        // localStorage.setItem("barcode", resData.data.data.barcode);
        console.log("hello", resData.data.data);
        setArival_city(resData.data.data.travel_details.arival_city);
        setTravel_mode(resData.data.data.travel_details.travel_mode);
        setArival_date_time(resData.data.data.travel_details.arival_date_time);
        setTrain_flight_name(
          resData.data.data.travel_details.train_flight_name
        );
        setTrain_flight_number(
          resData.data.data.travel_details.train_flight_number
        );
        setHotel_check_in_date(
          resData.data.data.hotel_detail.hotel_check_in_date
        );
        setDeparture_city(resData.data.data.travel_details.departure_city);
        setHotel_check_out_date(
          resData.data.data.hotel_detail.hotel_check_out_date
        );
        setRemark(resData.data.data.visitor_remark);
        // setTicket_photo(resData.data.data.travel_details.travel_ticket);
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
  }, []);
  return (
    <div>
      {" "}
      <div class="home-page">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="left-section">
                <div class="rubi-image">
                  <img
                    className="destop-view"
                    src={mobilelogo}
                    alt=""
                    style={{
                      width: "60%",
                      height: " 60%",
                      marginTop: "27%",
                      marginLeft: "18%",
                      objectFit: "contain",
                    }}
                  />
                  {/* <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  /> */}
                  <img
                    className="mobile-view size-n2"
                    src={mobilelogo}
                    alt=""
                  />
                  {/* <img class="destop-view size-n1" src={rubiesImage} alt="" /> */}

                  {/* <!-- <img class="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="right-section">
                <div class="right-top-section">
                  <div class="progress">
                    <div
                      class="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: "85.68%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div
                    className="fw-bold text-center"
                    style={{ fontSize: "26px", marginBottom: "10px" }}
                  >
                    Onward Journey
                  </div>
                  <form>
                    <div className="mb-3 destop-view">
                      <label htmlFor="exampleInputname" className="form-label">
                        Arrival (City)
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        name="companyName"
                        aria-describedby="name"
                        value={arival_city}
                        onChange={(e) => setArival_city(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        name="companyName"
                        placeholder="Arival (City)"
                        aria-describedby="name"
                        value={arival_city}
                        onChange={(e) => setArival_city(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3 destop-view">
                      <label htmlFor="exampleInputname" className="form-label">
                        Departure (City)
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        name="companyName"
                        aria-describedby="name"
                        value={departure_city}
                        onChange={(e) => setDeparture_city(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        name="companyName"
                        placeholder="Departure (City)"
                        aria-describedby="name"
                        value={departure_city}
                        onChange={(e) => setDeparture_city(e.target.value)}
                        required
                      />
                    </div>
                    <label htmlFor="cars">
                      Travel Mode
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </label>
                    <select
                      className="form-select mb-3 form-control"
                      onChange={(e) => setTravel_mode(e.target.value)}
                      value={travel_mode}
                      // defaultValue={'select mode of travel'} // Set the default value to the first item in the array
                    >
                      {travel_mode_array.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>

                    {travel_mode !== "Self" && (
                      <>
                        {/* <div className="mb-3 destop-view">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                          >
                            Arrival PNR
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            name="gstNumber"
                            value={arival_pnr}
                            onChange={(e) => setArival_pnr(e.target.value)}
                            required
                          />
                        </div> */}
                        <div className="mb-3 destop-view">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                          >
                            Date And Time of Arrival
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <DateTimerPicker
                            setTime={setArival_date_time}
                            time={arival_date_time}
                          />
                        </div>
                        <div className="mb-3 destop-view">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                          >
                            {travel_mode} name
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            name="pincode"
                            value={train_flight_name}
                            onChange={(e) =>
                              setTrain_flight_name(e.target.value)
                            }
                            required
                          />
                        </div>
                        <div className="mb-3 destop-view">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                          >
                            {travel_mode} Number
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            name="pincode"
                            value={train_flight_number}
                            onChange={(e) =>
                              setTrain_flight_number(e.target.value)
                            }
                            required
                          />
                        </div>
                        {/* <div className="mb-3 mobile-view">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            name="gstNumber"
                            placeholder="Arival PNR"
                            value={arival_pnr}
                            onChange={(e) => setArival_pnr(e.target.value)}
                            required
                          />
                        </div> */}
                        <div className="mb-3 mobile-view">
                          <DateTimerPicker
                            setTime={setArival_date_time}
                            time={arival_date_time}
                          />
                        </div>
                        <div className="mb-3 mobile-view">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder={`${travel_mode} Name`}
                            name="pincode"
                            value={train_flight_name}
                            onChange={(e) =>
                              setTrain_flight_name(e.target.value)
                            }
                            required
                          />
                        </div>
                        <div className="mb-3 mobile-view">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder={`${travel_mode} Number`}
                            name="pincode"
                            value={train_flight_number}
                            onChange={(e) =>
                              setTrain_flight_number(e.target.value)
                            }
                            required
                          />
                        </div>
                        {/* <div
                          action="/upload"
                          method="post"
                          encType="multipart/form-data"
                        >
                          <label>
                            Upload Travel Ticket
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="file"
                            name="fileUpload"
                            id="fileUpload"
                            onChange={(e) => setTicket_photo(e.target.files[0])}
                          />
                        </div> */}
                      </>
                    )}
                    <div class="mb-3 destop-view">
                      <label for="exampleInputname" class="form-label ">
                        Hotel Check-in Date
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      {/* <DateTimerPicker/> */}
                      <DatePicker
                        setDate={setHotel_check_in_date}
                        lable={"Check-in date"}
                      />

                      {/* <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.
                                                                                            </div> --> */}
                    </div>
                    <div class="mb-3 destop-view">
                      <label for="exampleInputPassword1" class="form-label">
                        Hotel Check-out Date
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <DatePicker
                        setDate={setHotel_check_out_date}
                        lable={"Check-out date"}
                      />
                    </div>
                    <div class="mb-3 mobile-view">
                      {/* <DateTimerPicker/> */}
                      <DatePicker
                        setDate={setHotel_check_in_date}
                        lable={"Check-in date"}
                      />

                      {/* <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.
                                                                                            </div> --> */}
                    </div>
                    <div class="mb-3 mobile-view">
                      <DatePicker
                        setDate={setHotel_check_out_date}
                        lable={"Check-out date"}
                      />
                    </div>

                    <div class="mb-3 destop-view">
                      <label for="exampleInputPassword1" class="form-label">
                        Remark
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      <input
                        type="mail"
                        class="form-control"
                        id="exampleInputPassword1"
                        // required
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </div>
                    <div class="mb-3 mobile-view">
                      <input
                        type="mail"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Remark"
                        required
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </div>

                    {errorMessage && (
                      <div style={{ color: "red" }}>{errorMessage}</div>
                    )}
                    <div class="two-btn">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setDataShow(dataShow - 1);
                        }}
                        class="btn btn-primary btn-primary-01 "
                      >
                        <a>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                          Back
                        </a>
                      </button>
                      <button
                        class="btn btn-primary btn-primary-01"
                        onClick={handleContinue}
                      >
                        <a>
                          Return Journey
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </form>
                  <img className="fixedlogo" src={fixedlogo} alt="" />

                  {/* <div class="progress">
                    <div
                      class="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: " 83.3%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelInforamtion;
