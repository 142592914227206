import React, { useEffect, useRef, useState } from "react";
import "../css/responsive.css";
import "../css/stylesheet.css";
import FirstPage from "./FirstPage";
import VerifyOtp from "./VerifyOtp";
import DetailsPresent from "./DetailsPresent";
import MissingDetailsOne from "./MissingDetailsOne";
import TravelInforamtion from "./TravelInforamtion";
import PaymentPage from "./PaymentPage";
import MissionDetails from "./MissionDetails";
import PaymentDetail from "./PaymentDetail";
// import HotelInformation from "./HotelInformation";
import axios from "axios";
import Thanks from "./Thanks";
import AddMember from "./AddMember";
import ReturnTravelInforamtion from "./ReturnTravelInforamtion";
// import logo from "./images/logo-01.png";

const JjsMainPage = () => {
  const [dataShow, setDataShow] = useState();
  const [phoneNo, setPhoneNo] = useState("");
  const [currentBuyer, setCurrentBuyer] = useState(null);
  const [reload, setReload] = useState(false);
  const [checkReload, setCheckReload] = useState(false);

  useEffect(() => {
    console.log(
      "localStorage.getItem(barcode)",
      localStorage.getItem("barcode")
    );
    if (localStorage.getItem("barcode")) {
      axios
        .post(`${process.env.REACT_APP_API_END_POINT}/get-detail-buyer`, "", {
          headers: {
            barcode: localStorage.getItem("barcode"),
          },
        })
        .then((resData) => {
          // localStorage.setItem("barcode", resData.data.data.barcode);
          setDataShow(resData.data.status);
        })
        .catch((error) => {
          console.log("error:", error.message);
        });
    } else {
      setDataShow(1);
    }
  }, [checkReload]);
  return (
    <div id="body">
      {dataShow === 1 && (
        <FirstPage
          setDataShow={setDataShow}
          dataShow={dataShow}
          phoneNo={phoneNo}
          setPhoneNo={setPhoneNo}
          currentBuyer={currentBuyer}
          setCurrentBuyer={setCurrentBuyer}
          reload={reload}
          setReload={setReload}
          checkReload={checkReload}
          setCheckReload={setCheckReload}
        />
      )}

      {dataShow === 2 && (
        <>
          <VerifyOtp
            setDataShow={setDataShow}
            dataShow={dataShow}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            currentBuyer={currentBuyer}
            setCurrentBuyer={setCurrentBuyer}
            reload={reload}
            setReload={setReload}
            checkReload={checkReload}
            setCheckReload={setCheckReload}
          />
        </>
      )}

      {dataShow === 3 && (
        <>
          <DetailsPresent
            setDataShow={setDataShow}
            dataShow={dataShow}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            currentBuyer={currentBuyer}
            setCurrentBuyer={setCurrentBuyer}
            reload={reload}
            setReload={setReload}
            checkReload={checkReload}
            setCheckReload={setCheckReload}
          />
        </>
      )}
      {dataShow === 4 && (
        <>
          {/* <MissionDetails setDataShow={setDataShow} dataShow={dataShow} /> */}

          <MissingDetailsOne
            setDataShow={setDataShow}
            dataShow={dataShow}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            currentBuyer={currentBuyer}
            setCurrentBuyer={setCurrentBuyer}
            reload={reload}
            setReload={setReload}
          />
        </>
      )}
      {dataShow === 5 && (
        <>
          <MissionDetails
            setDataShow={setDataShow}
            dataShow={dataShow}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            currentBuyer={currentBuyer}
            setCurrentBuyer={setCurrentBuyer}
            reload={reload}
            setReload={setReload}
          />
        </>
      )}
      {dataShow === 6 && (
        <>
          <AddMember
            setDataShow={setDataShow}
            dataShow={dataShow}
            phoneNo={phoneNo}
            currentBuyer={currentBuyer}
            setCurrentBuyer={setCurrentBuyer}
            setPhoneNo={setPhoneNo}
            reload={reload}
            setReload={setReload}
          />
        </>
      )}
      {dataShow === 7 && (
        <>
          <TravelInforamtion
            setDataShow={setDataShow}
            dataShow={dataShow}
            phoneNo={phoneNo}
            currentBuyer={currentBuyer}
            setCurrentBuyer={setCurrentBuyer}
            setPhoneNo={setPhoneNo}
            reload={reload}
            setReload={setReload}
          />
        </>
      )}

       {dataShow === 8 && (
        <>
          <ReturnTravelInforamtion
            setDataShow={setDataShow}
            dataShow={dataShow}
            phoneNo={phoneNo}
            currentBuyer={currentBuyer}
            setCurrentBuyer={setCurrentBuyer}
            setPhoneNo={setPhoneNo}
            reload={reload}
            setReload={setReload}
          />
        </>
      )}
      {/* {dataShow === 9 && (
        <>
          <PaymentDetail
            setDataShow={setDataShow}
            dataShow={dataShow}
            currentBuyer={currentBuyer}
            setCurrentBuyer={setCurrentBuyer}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            reload={reload}
            setReload={setReload}
          />
        </>
      )}  */}

      {dataShow === 9 && (
        <>
          <Thanks />
        </>
      )}
    </div>
  );
};

export default JjsMainPage;
