import React, { useEffect, useRef, useState } from "react";

// import logo from "../images/logo-01.png";
import logo from "../images/fixed-logo.png";
import mobilelogo from "../images/pmi-grand-w-logo.png";
import fixedlogo from "../images/fixed-logo.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/pmi-grand-w-logo.png";
import axios from "axios";
import { message } from "antd";
const AddMember = ({
  setDataShow,
  dataShow,
  phoneNo,
  currentBuyer,
  setCurrentBuyer,
  setPhoneNo,
  reload,
  setReload,
}) => {
  const [aadharFront, setAadharFront] = useState(null);
  const [aadharBack, setAadharBack] = useState(null);
  const [yourPhoto, setYourPhoto] = useState(null);
  const [guest_name, setGuest_name] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [phone, setPhone] = useState("");

  const handleAadharFrontChange = (e) => {
    setAadharFront(e.target.files[0]);
  };

  const handleAadharBackChange = (e) => {
    setAadharBack(e.target.files[0]);
  };

  const handleYourPhotoChange = (e) => {
    setYourPhoto(e.target.files[0]);
  };
  const handleContinue = (e) => {
    e.preventDefault();
    if (
      !guest_name ||
      !aadharFront ||
      !aadharBack ||
      !yourPhoto ||
      !email ||
      !designation
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }
    const formData = new FormData();
    formData.append("mobile_number", phone);
    formData.append("designation", designation);
    formData.append("email", email);
    formData.append("visitor_name", guest_name);
    formData.append("id_proof_front", aadharFront);
    formData.append("id_proof_back", aadharBack);
    formData.append("photo", yourPhoto);
    // formData.append("barcode", barCode)

    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/add-guest-data-buyer`,
        formData,
        {
          headers: {
            barcode: localStorage.getItem("barcode"),
          },
        }
      )
      .then((resData) => {
        message.success(resData.data.message);
      })
      .catch((error) => {
        console.log("error:", error.message);
      });

    setDataShow(dataShow + 1);
    setReload(!reload);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/get-guest-data-buyer`, {
        headers: {
          barcode: localStorage.getItem("barcode"),
        },
      })
      .then((resData) => {
        if (resData.data.data.length > 0) {
          setGuest_name(resData.data.data[0].visitor_name);
          setAadharFront(resData.data.data[0].id_proof_front);
          setAadharBack(resData.data.data[0].id_proof_back);
          setYourPhoto(resData.data.data[0].photo);
          setEmail(resData.data.data[0].email);
          setPhone(resData.data.data[0].mobile_number);
          setDesignation(resData.data.data[0].designation);
        }
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
  }, []);
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setErrorMessage("Please enter a valid email address");
    } else {
      setErrorMessage("");
    }
  };
  const handleMobileNo = (e) => {
    setPhone(e.target.value);
    if (/^\d{0,10}$/.test(e.target.value)) {
      setErrorMessage(null);
    } else {
      setErrorMessage("mobile number contain only numbers");
    }
  };
  return (
    <div>
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img
                    class="destop-view"
                    src={mobilelogo}
                    alt=""
                    style={{
                      width: "60%",
                      height: " 60%",
                      marginTop: "27%",
                      marginLeft: "18%",
                      objectFit: "contain",
                    }}
                  />

                  {/* <img class="destop-view size-n1" src={rubiesImage} alt="" /> */}
                  <img class="mobile-view size-n2" src={mobilelogo} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                {" "}
                <div className="progress mt-5">
                  <div
                    className="progress-bar "
                    role="progressbar"
                    aria-label="Default striped example"
                    style={{
                      width: "71.4%",
                      ariaValuenow: "10",
                      ariaValuemin: "0",
                      ariaValuemax: "100",
                    }}
                  ></div>
                </div>
                <div
                  className="fw-bold text-center"
                  style={{ fontSize: "26px", marginBottom: "10px" }}
                >
                  <snap>Member details</snap>
                </div>
                <div className="right-top-section mt-5">
                  <form id="nt-1">
                    <>
                      {" "}
                      <div class="mb-3 destop-view">
                        <label for="exampleInputPassword1" class="form-label">
                          Name of Guest
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="mail"
                          class="form-control"
                          id="exampleInputPassword1"
                          required
                          value={guest_name}
                          onChange={(e) => setGuest_name(e.target.value)}
                        />
                      </div>
                      <div class="mb-3 destop-view">
                        <label for="exampleInputPassword1" class="form-label">
                          Email Address
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputPassword1"
                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                      </div>
                      <div className="mb-3 destop-view">
                        <label htmlFor="cars">
                          Designation
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputName"
                          aria-describedby="datetime"
                          placeholder="Designation"
                          required
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                        />
                      </div>
                      <div class="mb-3 destop-view">
                        <label for="exampleInputPassword1" class="form-label">
                          Phone
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputPassword1"
                          value={phone}
                          maxLength={10}
                          onChange={handleMobileNo}
                        />
                      </div>
                      <div class="mb-3 mobile-view">
                        <input
                          type="natexttextme"
                          class="form-control"
                          id="exampleInputName"
                          aria-describedby="datetime"
                          placeholder="Guest name"
                          value={guest_name}
                          onChange={(e) => setGuest_name(e.target.value)}
                          required
                        />
                      </div>
                      <div class="mb-3 mobile-view">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputPassword1"
                          placeholder="enter your mail"
                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                      </div>
                      <div className="mb-3 mobile-view">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputName"
                          aria-describedby="datetime"
                          placeholder="Designation"
                          required
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                        />
                      </div>
                      <div class="mb-3 mobile-view">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputPassword1"
                          placeholder="Phone"
                          value={phone}
                          maxLength={10}
                          onChange={handleMobileNo}
                        />
                      </div>
                      <div
                        action="/upload"
                        method="post"
                        enctype="multipart/form-data"
                        className="input-group"
                      >
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupFile"
                        >
                          Upload Aadhar Front
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="file"
                          name="fileUpload"
                          // id="fileUpload"
                          id="inputGroupFile"
                          onChange={handleAadharFrontChange}
                          required
                          className="form-control"
                        />
                      </div>
                      <div
                        action="/upload"
                        method="post"
                        enctype="multipart/form-data"
                        className="input-group"
                      >
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupFile-1"
                        >
                          Upload Aadhar Back
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="file"
                          name="fileUpload"
                          id="inputGroupFile-1"
                          onChange={handleAadharBackChange}
                          required
                          className="form-control"
                        />
                      </div>
                      <div
                        action="/upload"
                        method="post"
                        enctype="multipart/form-data"
                        className="input-group"
                      >
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupFile-2"
                        >
                          Upload Your Photo
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="file"
                          name="fileUpload"
                          id="inputGroupFile-2"
                          onChange={handleYourPhotoChange}
                          className="form-control"
                        />
                      </div>
                    </>
                    {errorMessage && (
                      <div style={{ color: "red" }}>{errorMessage}</div>
                    )}
                    <div class="two-btn">
                      <button
                        class="btn btn-primary btn-primary-01 "
                        onClick={(e) => {
                          e.preventDefault();
                          setDataShow(dataShow - 1);
                        }}
                      >
                        <a>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                          Back
                        </a>
                      </button>
                      <br />
                      <br />
                      <button
                        type="submit"
                        onClick={handleContinue}
                        class="btn btn-primary btn-primary-01"
                      >
                        <a>
                          Continue
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </form>
                  <img className="fixedlogo" src={fixedlogo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMember;
