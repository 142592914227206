import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect, useState } from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';

export default ({ dummyPage,totalLength, limit, pageNo }) => {
  const [page, setPage] = useState(pageNo || 1);

  useEffect(()=>{
     dummyPage(page)
     console.log(totalLength, limit, pageNo,"totalLength, limit, pageNo")
  },[page])

  return (
    <PaginationControl
      page={page}
      total={totalLength}
      limit={limit}
      changePage={(page) => {
        setPage(page);
      }}
      // ellipsis={1}
    />
  );
}
