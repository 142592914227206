import React, { useEffect } from "react";
import { Button } from "react-bootstrap"; // Correct import statement

function Error404() {
    console.log("asdassdadsadad","asasdsdasdasdsad")
    useEffect(()=>{
       localStorage.removeItem("jff_token");
       localStorage.removeItem("category");
       localStorage.removeItem("permission");
       localStorage.removeItem("user_id");
       localStorage.removeItem("type_of_user");
        
    },[])
    return (
        <>
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <h1 className="display-1 fw-bold">404</h1>
                    <p className="fs-3">
                        {" "}
                        <span className="text-danger">Opps!</span> Page not found.
                    </p>
                    <p className="lead">The page you’re looking .</p>
                    {/* <Button>Back to login</Button> */}
                    {/* <Button variant="primary">Go Home</Button> Correct usage */}
                </div>
            </div>
        </>
    );
}

export default Error404;
